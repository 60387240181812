









































































































import { Vue, Component, Prop } from "vue-property-decorator";
import apiService from "@/services/apiService.ts";
import SnackbarMessage from "../components/SnackbarMessage.vue";
import { Guid } from "guid-typescript";
import UserModel from "@/models/userModel";
import UserResetPasswordModel from "@/models/UserResetPasswordModel";
import Validation from "@/mixins/validation";

@Component
export default class Profile extends Validation {
  @Prop(String) readonly id!: string;

  private user: UserModel = new UserModel();
  private userPassword: UserResetPasswordModel = new UserResetPasswordModel();
  private showPassword: boolean = false;
  private showConfirmPassword: boolean = false;
  private passwordDialog = false;
  private newPassword: string = "";
  private snackbar: boolean = false;
  private successMesssage: string = "";

  private locations: any[] = ["Oldmeldrum", "West Bromwich"];

  private async created() {
    await this.getUser();
  }

  get passwordsMatch() {
    return (
      this.userPassword.password === this.userPassword.confirmPassword ||
      "Password must match"
    );
  }

  private async returnToHome() {
    this.$router.push({
      name: "home"
    });
  }

  private async getUser() {
    this.user = await apiService.get<UserModel>("/user", Guid.parse(this.id));
  }

  private async saveProfile() {
    await apiService.put<UserModel>("/user", Guid.parse(this.id), this.user);
  }

  private async passwordConfirm() {
    await apiService.postResetPassword(
      Guid.parse(this.id),
      this.userPassword.password
    );
    this.passwordDialog = false;
    this.snackbar = true;
    this.successMesssage = "Password has been successfully updated.";
    this.userPassword.password = "";
    this.userPassword.confirmPassword = "";
  }
}
